.unityCanvas {
 width: 100%;
 height: 100%;  
}
.unityDiv {
 width: 100%;
 height: 100vh;
 background-color: rgb(18, 131, 93);


 
}
@media (max-width: 800px)
 {
    .unityDiv{
        /* height: 87vh; */
    }
 }

 .loading{
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }
 .loadText{
    /* text-align: center; */
 }
 .LoadingContainer{
    background-color: rgb(18, 131, 93);
    width: 100%;
 }
.unityContainer {
    
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}


.progBar {
    width: 50%;
   
}

.team-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .members-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Creates space between the cards */
  }
  
  .member-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px; /* Fixed width for each card */
    padding: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Optional: adds a shadow for better visual separation */
    text-align: center;

    img{
       max-width:300px;
       width: 100%;
       height: auto; 
    }
  }
  
  @media (max-width: 600px) {
    .members-container {
      flex-direction: column;
    }
  }